import { graphql } from 'gatsby'
import get from 'lodash/get'
import React, { Fragment } from 'react'

import Post from 'templates/Post'
import Meta from 'components/Meta'
import Layout from 'components/Layout'
import Page from 'templates/Page'

const Template = ({ data, location }) => (
  <div>
    <Layout location={location}>
      {get(data, 'post.frontmatter.layout') != 'page' ? (
        <Fragment>
          <Meta
            title={get(data, 'post.frontmatter.title')}
            description={get(data, 'post.frontmatter.description')}
            site={get(data, 'site.meta')}
            image={get(data, 'post.frontmatter.image.childImageSharp.fluid.src')}
          />
          <Post
            data={get(data, 'post')}
            options={{
              isIndex: false,
              adsense: get(data, 'site.meta.adsense'),
            }}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Meta
            title={get(data, 'post.frontmatter.title')}
            site={get(data, 'site.meta')}
          />
          <Page {...this.props} />
        </Fragment>
      )}
    </Layout>
  </div>
)
export default Template

export const pageQuery = graphql`
  query PostByPath($path: String!) {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        layout
        title
        path
        category
        tags
        description
        date
        image {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
